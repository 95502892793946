<template id="app">
  <div
    :class="{
      testBackground: useTestEnvironmentBackground,
      prodBackground: !useTestEnvironmentBackground,
    }"
  >
    <md-toolbar
      v-if="serviceNotice"
      class="serviceNoticeBar md-transparent md-dense"
    >
      <div class="serviceNotice">
        {{ serviceNotice }}
      </div>
      <div>
        <md-button class="md-icon-button" @click="closeServiceNotice()"
          ><md-icon>close</md-icon></md-button
        >
      </div>
    </md-toolbar>
    <!-- Keep alive: handles refresh of token and logout on inactive -->
    <app-loginpage></app-loginpage>
    <router-view v-if="user && validCountry"></router-view>
    <md-snackbar
      :md-active.sync="showSnackbar"
      :md-duration="snackbarDuration"
      md-persistent
    >
      <span>
        {{ errorMessage.message || errorMessage || statusMessage }}
        <md-tooltip
          v-if="errorMessage.details || statusMessage"
          md-direction="top"
          >{{ errorMessage?.details ?? statusMessage }}</md-tooltip
        ></span
      >
      <md-button @click="showSnackbar = false">Close</md-button>
    </md-snackbar>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import store from "./store";
import appLoginPage from "./components/appLoginPage";

export default {
  name: "App",
  store,
  components: {
    "app-loginpage": appLoginPage,
  },
  data: () => ({
    hasServiceNotice: false,
    showSnackbar: false,
    snackbarDuration: 4000,
  }),
  computed: {
    ...mapState([
      "user",
      "statusMessage",
      "errorMessage",
      "serviceNotice",
      "validCountry",
      "statusMessage",
    ]),
    useTestEnvironmentBackground() {
      const hostname = window.location.hostname.toLowerCase();
      return (
        hostname.includes("test.") &&
        !window.location.search.includes("useProductionColors")
      );
    },
  },
  watch: {
    errorMessage() {
      if (this.errorMessage) {
        this.showSnackbar = true;
        this.snackbarDuration = 10000;
      }
    },
    statusMessage() {
      if (this.statusMessage) {
        this.showSnackbar = true;
        this.snackbarDuration = 4000;
      }
    },
    showSnackbar() {
      if (!this.showSnackbar) {
        this.setErrorMessage("");
        this.setStatusMessage("");
      }
    },
  },
  created() {
    this.getOrCreateUniqueSessionId();
  },
  methods: {
    ...mapMutations([
      "setErrorMessage",
      "setServiceNotice",
      "setStatusMessage",
    ]),
    closeServiceNotice() {
      this.setServiceNotice("");
    },
    getOrCreateUniqueSessionId() {
      if (window.localStorage["sessionId"]) return;
      const guid = crypto.randomUUID();
      window.localStorage["sessionId"] = guid;
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
.testBackground {
  background: #797979;
  min-height: 100%;
}
.prodBackground {
  background: linear-gradient(to left bottom, #3a8ca5, #122a31);
  min-height: 100%;
}
</style>

<style scoped>
.md-snackbar {
  background-color: #337789;
  border-radius: 10px;
  font-size: 16px;
}
.md-tooltip {
  background: #337789;
  border-radius: 10px;
  font-size: 16px;
}
.serviceNotice {
  font-size: 1em;
  font-weight: bold;
}
.serviceNoticeBar {
  display: flex;
  justify-content: center;
  width: 100%;
}
</style>
