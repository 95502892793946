export default class LumiApi {
  async getErrorMessage(result) {
    const message =
      result.status === 401
        ? "Unauthorized"
        : result.status === 404
        ? "Not found"
        : result.status === 412
        ? "Another user updated this item"
        : "Error occurred";
    return {
      message,
      details: await result.text(),
    };
  }

  async executeApiRequest(
    commit,
    url,
    method,
    idToken,
    body,
    additionalHeaders,
    responseType,
    errorHandler,
    successHandler
  ) {
    const localErrorHandler = errorHandler
      ? errorHandler
      : (error) => {
          commit("setErrorMessage", error);
        };
    try {
      let headers = this.mergeObjects(additionalHeaders, {
        Authorization: `Bearer ${idToken}`,
        "Session-Id": `${window.localStorage["sessionId"] ?? ""}`,
      });
      const result = await fetch(url, {
        method,
        headers,
        body,
      });
      return await this.parseResponse(
        result,
        responseType,
        method,
        successHandler,
        localErrorHandler
      );
    } catch (e) {
      localErrorHandler(e);
    }
  }

  async parseResponse(
    response,
    responseType,
    method,
    successHandler,
    errorHandler
  ) {
    if (response.status < 200 || response.status > 202) {
      const error = await this.getErrorMessage(response);
      errorHandler(error);
    } else {
      if (successHandler) successHandler();
      switch (responseType || "json") {
        case "text":
          return await response.text();
        case "json":
          return await response.json();
        case "response":
          return response;
        default:
          errorHandler(
            new Error("unknown response type requested: " + responseType)
          );
      }
    }
  }

  async executeApiJsonRequest(
    commit,
    url,
    method,
    idToken,
    json,
    additionalHeaders,
    responseType,
    errorHandler,
    successHandler
  ) {
    return await this.executeApiRequest(
      commit,
      url,
      method,
      idToken,
      JSON.stringify(json),
      this.mergeObjects(additionalHeaders, {
        "Content-Type": "application/json",
      }),
      responseType,
      errorHandler,
      successHandler
    );
  }

  async loadResources(commit, url, idToken) {
    let resources = await this.executeApiRequest(commit, url, "GET", idToken);
    return this.sortResources(resources);
  }

  async deleteResource(commit, url, idToken, errorHandler, successHandler) {
    return await this.executeApiRequest(
      commit,
      url,
      "DELETE",
      idToken,
      undefined,
      undefined,
      "text",
      errorHandler,
      successHandler
    );
  }

  mergeObjects(a, b) {
    return { ...(a || {}), ...(b || {}) };
  }

  sortResources(resources) {
    resources.sort((a, b) => {
      let namea = a.metadata ? a.metadata.displayName || a.id : a.id;
      let nameb = b.metadata ? b.metadata.displayName || b.id : b.id;
      return namea > nameb ? 1 : -1;
    });
    return resources;
  }
}
