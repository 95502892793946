<template id="event-stream">
  <div style="height: 0px"></div>
</template>

<script>
import appSettings from "./appSettings";
import { mapState } from "vuex";

export default {
  name: "EventStream",
  data: () => ({
    isReconnecting: false,
    pingEvent: null,
  }),
  computed: {
    ...mapState(["idToken", "tokenExpiry"]),
  },
  watch: {
    idToken() {
      this.connectToWebsocket();
    },
  },
  created() {
    this.connectToWebsocket();
  },
  methods: {
    onEvent(e) {
      if (e.data == "pong") return; // Keep alive
      this.$emit("eventgrid-event", JSON.parse(e.data));
    },
    connectToWebsocket() {
      if (!this.idToken) return;

      this.websocket = new WebSocket(
        `${appSettings.remotewsurl}/api/event?access_token=${
          this.idToken
        }&session_id=${window.localStorage["sessionId"] ?? ""}`
      );
      this.websocket.onmessage = this.onEvent;
      this.websocket.onclose = this.onClose;
      this.websocket.onerror = this.onError;
      this.websocket.onopen = this.onOpen;
    },
    onOpen() {
      const websocket = this.websocket;
      this.pingEvent = setInterval(function () {
        websocket.send("ping");
      }, 5000);
    },
    onClose(event) {
      if (this.pingEvent) {
        clearInterval(this.pingEvent);
        this.pingEvent = null;
      }
      this.isReconnecting = false;
      this.$appInsights.trackEvent({
        name: "socket-closed",
        properties: {
          code: event.code,
          reason: event.reason,
        },
      });
      this.attemptReconnect();
    },
    onError() {
      // On error provides little information so just provide a generic message to app insights
      this.$appInsights.trackException({
        exception: new Error("Socket closed with error"),
      });
      this.attemptReconnect();
    },
    attemptReconnect() {
      if (this.isReconnecting) return;
      this.isReconnecting = true;
      this.connectToWebsocket();
    },
  },
};
</script>
