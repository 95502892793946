<template id="app-file-dropper">
  <div v-if="!isReadOnlyGroup" class="dropZone">
    <div v-if="!selectedAsset" id="closeButton">
      <md-button class="md-icon-button" @click="setCreating(false)"
        ><md-icon>close</md-icon></md-button
      >
    </div>
    <div
      class="uploadZone"
      :class="{ highlight: highlighted }"
      @dragenter.prevent.stop="highlighted = true"
      @dragleave.prevent.stop="highlighted = false"
      @dragover.prevent.stop="highlighted = true"
      @drop.prevent.stop="
        scanDroppedFiles($event.dataTransfer);
        highlighted = false;
      "
    >
      <div
        v-if="uploadStage !== uploadStages.UPLOADING"
        class="innerUploadZone"
      >
        <div class="dropText">
          <div v-if="isHome">Drop DICOM files here</div>
          <div v-else>Drop files here</div>
        </div>
        <div id="dropZoneIcons">
          <div class="iconAndLabel">
            <md-button class="filterButton"
              ><div
                id="folderButton"
                class="filterIcon"
                :style="{
                  backgroundImage: 'url(/assets/icon/folder.svg)',
                }"
                @click="selectFiles"
              >
                &nbsp;
              </div></md-button
            >
            <div class="caption">
              <div v-if="isHome">Select a DICOM Folder</div>
              <div v-else>
                Select {{ $attrs.directory ? "a Folder" : "Files" }}
              </div>
            </div>
            <input
              id="fileSelect"
              :accept="$attrs.accept"
              :directory="$attrs.directory"
              hidden
              multiple
              type="file"
              :webkitdirectory="$attrs.directory"
              @change="onSelectFolder($event)"
            />
          </div>

          <div v-if="isHome" class="iconAndLabel">
            <md-button class="filterButton"
              ><div
                class="filterIcon"
                :style="{
                  backgroundImage: 'url(/assets/icon/PersonIcon.svg)',
                }"
                @click="openPatientDialog"
              >
                &nbsp;
              </div></md-button
            >
            <div class="caption">
              <div>Create a Patient manually</div>
            </div>
          </div>
          <div v-else-if="!selectedAsset" class="iconAndLabel">
            <md-button class="filterButton"
              ><div
                class="filterIcon"
                :style="{
                  backgroundImage: 'url(/assets/icon/SetIcon.svg)',
                }"
                @click="createEmptyHologram"
              >
                &nbsp;
              </div></md-button
            >
            <div class="caption">
              <div>Create a Hologram manually</div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!selectedAsset" class="footer"></div>
      <div class="spacer"></div>
      <div
        v-if="uploadStage === uploadStages.SCANNING"
        style="display: flex; flex-direction: column"
      >
        Analyzing files...
        <br />
        <div class="spacer"></div>
        <md-progress-spinner
          :md-diameter="25"
          md-mode="indeterminate"
          :md-stroke="2"
        ></md-progress-spinner>
      </div>
      <div
        v-if="uploadStage === uploadStages.ERROR"
        style="display: flex; flex-direction: column"
      >
        <br />
        <div class="spacer"></div>
        <small> Something went wrong when uploading. Please try again! </small>
        <div class="spacer"></div>
        <div
          class="btn btn-danger"
          @click="uploadStage = uploadStages.INACTIVE"
        >
          Try Again
        </div>
      </div>
    </div>
    <md-dialog
      :md-active.sync="showScanningFiles"
      :md-click-outside-to-close="false"
      :md-close-on-esc="false"
    >
      <md-dialog-title>Please wait</md-dialog-title>
      <md-dialog-content>
        <span>Scanning files</span>
      </md-dialog-content>
      <md-dialog-actions>
        <md-button
          class="md-primary"
          @click="uploadStage = uploadStages.INACTIVE"
          >Cancel</md-button
        >
      </md-dialog-actions>
    </md-dialog>

    <md-dialog
      :md-active.sync="invalidDicomUpload"
      :md-click-outside-to-close="false"
      :md-close-on-esc="false"
    >
      <md-dialog-title>Attention!</md-dialog-title>
      <md-dialog-content>
        <img src="/assets/icon/warning.svg" />
        <span>Please upload DICOM from the home page</span>
      </md-dialog-content>
      <md-dialog-actions>
        <md-button
          class="md-primary"
          @click="uploadStage = uploadStages.INACTIVE"
          >Ok</md-button
        >
      </md-dialog-actions>
    </md-dialog>

    <md-dialog
      :md-active.sync="showUploadConfirm"
      :md-click-outside-to-close="false"
      :md-close-on-esc="false"
    >
      <md-dialog-title>Attention!</md-dialog-title>
      <md-dialog-content id="ensureAnonymized">
        <div v-if="!selectedAsset" id="chooseAssetType">
          <div style="width: 100px; padding-top: 27px; margin-right: 5px">
            Creating a
          </div>
          <md-field>
            <md-select
              id="assetType"
              v-model="analyzedFileInfo.assetType"
              name="type"
            >
              <md-option value="DICOMStudy">DICOM Study</md-option>
              <md-option value="Set">Hologram</md-option>
              <md-option value="Volume">Volume</md-option>
              <md-option value="Surface">Surface</md-option>
              <md-option value="PointCloud">PointCloud</md-option>
              <md-option value="Draw">Draw</md-option>
              <md-option value="Trepanation">Trepanation</md-option>
              <md-option value="Unknown">Unknown</md-option>
            </md-select>
          </md-field>
        </div>
        <div>
          <img src="/assets/icon/warning.svg" />
          <span
            >Please ensure that you are only uploading anonymized data!</span
          >
        </div>
      </md-dialog-content>
      <md-dialog-actions>
        <md-button
          class="md-primary md-raised"
          @click="uploadFiles(analyzedFileInfo)"
          >Confirm</md-button
        >
        <md-button
          class="md-primary"
          @click="uploadStage = uploadStages.INACTIVE"
          >Cancel</md-button
        >
      </md-dialog-actions>
    </md-dialog>
    <md-dialog
      :md-active.sync="emptyFolder"
      :md-click-outside-to-close="false"
      :md-close-on-esc="false"
    >
      <md-dialog-title>Attention!</md-dialog-title>
      <md-dialog-content>
        <img src="/assets/icon/warning.svg" />
        <span>Empty folder detected, aborting upload.</span>
      </md-dialog-content>
      <md-dialog-actions>
        <md-button
          class="md-primary md-raised"
          @click="
            uploadStage = uploadStages.INACTIVE;
            emptyFolder = false;
          "
          >Ok</md-button
        >
      </md-dialog-actions>
    </md-dialog>
    <app-file-dropper-progress
      :upload-progress="uploadProgress"
      :upload-stage="uploadStage"
      @cancelUpload="cancelDuringUpload()"
    >
    </app-file-dropper-progress>
    <app-dicom-upload
      :file-upload-info="analyzedFileInfo"
      @cancelUpload="cancelUpload()"
      @onPatientInfo="onPatientInfo($event)"
    >
    </app-dicom-upload>
    <app-stl-upload
      :file-upload-info="analyzedFileInfo"
      @cancelUpload="cancelUpload()"
      @uploadFiles="uploadFiles($event)"
    >
    </app-stl-upload>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import appSettings from "./appSettings";
import uploadStages from "../enums/UploadStages.js";
import appFileDropperProgress from "./appFileDropperProgress";
import appDicomUpload from "./appDicomUpload";
import appStlUpload from "./appStlUpload";
import FileUploadInfo from "../classes/FileUploadInfo.js";
import deleteModes from "../enums/DeleteModes.js";

export default {
  name: "AppFileDropper",
  components: {
    "app-file-dropper-progress": appFileDropperProgress,
    "app-dicom-upload": appDicomUpload,
    "app-stl-upload": appStlUpload,
  },
  data: () => ({
    uploadStages: uploadStages,
    highlighted: false,
    emptyFolder: false,
    files: [],
    analyzedFileInfo: new FileUploadInfo(),
    uploadStage: "inactive",
    uploadProgress: 0,
  }),
  computed: {
    ...mapState(["selectedGroup", "selectedAsset", "user"]),
    ...mapGetters(["isReadOnlyGroup", "isHome", "lastBreadcrumb"]),
    showScanningFiles() {
      return this.uploadStage === uploadStages.SCANNING;
    },
    showUploadConfirm() {
      return this.uploadStage === uploadStages.CONFIRM_ANONYMOUS;
    },
    invalidDicomUpload() {
      return this.uploadStage === uploadStages.INVALID_DICOM_UPLOAD;
    },
    showDicomDetected() {
      return this.uploadStage === uploadStages.DICOM_DETECTED;
    },
    isCancelled() {
      return this.uploadStage === uploadStages.CANCELLED;
    },
  },
  watch: {
    async uploadStage(stage) {
      switch (stage) {
        case uploadStages.INACTIVE:
          this.setInitialUploadState();
          break;
      }
    },
  },
  created() {
    this.uploadStage = uploadStages.INACTIVE;
  },
  methods: {
    ...mapMutations([
      "setSelectedAsset",
      "deleteAssetFormatFromState",
      "setCreating",
      "updateProgress",
      "setErrorMessage",
    ]),
    ...mapActions([
      "fetchAzure",
      "putAzure",
      "loadAsset",
      "createFormat",
      "deleteAzure",
      "getAzure",
      "postAzure",
      "postAzureForm",
      "updateAssetMetadata",
    ]),
    selectFiles() {
      this.uploadStage = uploadStages.SCANNING;
      document.querySelector("#fileSelect").click();
    },
    async openPatientDialog() {
      this.analyzedFileInfo = {
        needsPatientInfo: true,
        PatientID: this.generatePatientId(),
        assetGroupId: this.selectedGroup.id,
      };
      this.uploadStage = uploadStages.PATIENT_INFO;
    },
    generatePatientId() {
      const part1 = this.generatePatientIdPart(4);
      const part2 = this.generatePatientIdPart(4);
      const part3 = this.generatePatientIdPart(3);
      return `${part1}-${part2}-${part3}`;
    },
    generatePatientIdPart(length) {
      return Array.from(Array(length), () =>
        Math.floor(Math.random() * 36).toString(36)
      ).join("");
    },
    createPatientMetadata(patientInfo) {
      return {
        _type: "Person",
        _folder: true,
        displayName: patientInfo.PatientName,
        PatientName: patientInfo.PatientName,
        PatientId: patientInfo.PatientID,
        PatientSex: patientInfo.PatientSex,
        BirthDate: patientInfo.PatientBirthDate,
        FamilyName: patientInfo.FamilyName,
        GivenName: patientInfo.GivenName,
      };
    },
    async createEmptyHologram() {
      const url = `${this.getEmptyHologramUrl()}`;
      let emptyHologram = await this.getAzure({ url });
      await this.createHologram(emptyHologram);
    },
    async createHologram(hologramSet) {
      const hologramAsset = await this.createAsset({
        _type: "Set",
      });

      const url = `${this.getHologramRevisionUrl(hologramAsset.id)}`;
      await this.postAzure({
        url,
        metadata: hologramSet,
      });
      await this.setLatestHologram(hologramAsset);
    },
    async updateHologram(hologramSet) {
      const url = `${this.getHologramRevisionUrl(this.selectedAsset.id)}`;
      const formats = this.selectedAsset.formats;
      if (formats.length === 0) {
        throw "no revisions found in hologram, please create a valid hologram first";
      }
      await this.putAzure({
        url,
        metadata: hologramSet,
        ifMatchValue: formats[formats.length - 1].id,
      });
      await this.setLatestHologram(this.selectedAsset);
    },
    async setLatestHologram(asset) {
      const hologramWithFormat = await this.loadAsset({
        groupId: this.selectedGroup.id,
        assetId: asset.id,
        includeDetails: true,
      });
      this.setSelectedAsset(hologramWithFormat);
      this.setInitialUploadState();
    },
    async onFilesRead() {
      if (this.files.length === 0) {
        this.emptyFolder = true;
        return;
      }
      let analyzedFileInfo = await this.tryParseDicomFile();
      if (this.isInvalidDicomUpload(analyzedFileInfo)) {
        this.uploadStage = uploadStages.INVALID_DICOM_UPLOAD;
        return;
      }
      this.analyzedFileInfo = analyzedFileInfo;
      if (this.selectedAsset) {
        // within an asset just add the files to the asset
        this.uploadStage = uploadStages.CONFIRM_ANONYMOUS;
      } else if (analyzedFileInfo.isDicom) {
        this.analyzedFileInfo.needsPatientInfo = true;
        this.uploadStage = uploadStages.DICOM_DETECTED;
      } else if (analyzedFileInfo.isStl) {
        this.uploadStage = uploadStages.SURFACE_DETECTED;
      } else {
        this.uploadStage = uploadStages.CONFIRM_ANONYMOUS;
      }
    },
    isInvalidDicomUpload(fileInfo) {
      return fileInfo.isDicom && !this.isHome;
    },
    async tryParseDicomFile() {
      try {
        let potentialDicomFiles = this.getPotentialDicomFiles();
        for (let file of potentialDicomFiles) {
          let analyzedFileInfo = await this.parseFile(file);
          analyzedFileInfo.assetGroupId = this.selectedGroup.id;
          if (analyzedFileInfo.isDicom) {
            analyzedFileInfo.hasMixedFileTypes =
              potentialDicomFiles.length != this.files.length;
            return new FileUploadInfo(this.files, analyzedFileInfo);
          }
        }
        return new FileUploadInfo(this.files);
      } catch (e) {
        this.uploadStage = uploadStages.ERROR;
        throw "Error analyzing DICOM files";
      }
    },
    getPotentialDicomFiles() {
      return this.files
        .filter((file) => {
          const fileName = file.file.name;
          return (
            fileName.toLowerCase() !== "dicomdir" && // skip dicomdir, it can be different for anonymized studies
            (!fileName.includes(".") || // DICOM files have no extension
              fileName.toLowerCase().includes(".dcm")) // or .dcm extension
          );
        })
        .sort((a, b) => {
          return b.file.size - a.file.size;
        });
    },
    onSelectFolder(folder) {
      for (let file of folder.target.files) {
        this.files.push({
          file,
          fullPath: file.webkitRelativePath || file.name,
        });
      }
      this.uploadStage = uploadStages.SCANNING;
      this.onFilesRead();
    },
    async scanDroppedFiles(dataTransfer) {
      this.uploadStage = uploadStages.SCANNING;
      // First get entries: apparently after calling the .file()
      // the web entries on top level are invalid, so dragging in multiple folders will fail
      const items = [];
      for (const item of dataTransfer.items) {
        items.push(await item.webkitGetAsEntry());
      }
      this.analyzedFileInfo.files = [];
      for (let i = 0; i < items.length; i++) {
        let entry = items[i];
        if (entry) {
          await this.scanFiles(this.files, entry);
        }
      }
      this.onFilesRead();
    },
    async scanFiles(files, entry) {
      if (entry.isFile) {
        files.push({
          file: await this.getFile(entry),
          fullPath: entry.fullPath.substr(1),
        });
      } else if (entry.isDirectory) {
        let directoryReader = entry.createReader();
        let entries = await this.getEntries(directoryReader);
        while (entries.length > 0) {
          for (let i = 0; i < entries.length; i++) {
            await this.scanFiles(files, entries[i]);
          }
          entries = await this.getEntries(directoryReader);
        }
      }
    },
    async getEntries(directoryReader) {
      return await new Promise((resolve, reject) =>
        directoryReader.readEntries(resolve, reject)
      );
    },
    async getFile(fileEntry) {
      return await new Promise((resolve, reject) =>
        fileEntry.file(resolve, reject)
      );
    },
    async upload(url, formData, isPartialUpload) {
      const responseType = "json";
      return await this.postAzureForm({
        url,
        formData,
        isPartialUpload,
        responseType,
      });
    },
    async parseFile(file) {
      try {
        return this.tryParseFile(file);
      } catch {
        // Retry: the file might not have been ready
        return this.tryParseFile(file);
      }
    },
    async tryParseFile(file) {
      const formdata = new FormData();
      formdata.append(file.fullPath, file.file);
      return await this.upload(
        `${appSettings.anonymizerurl}/api/parse`,
        formdata,
        false
      );
    },
    createFormData(fileUploadInfo, files, batchSize) {
      const formdata = new FormData();
      if (fileUploadInfo.isDicom) {
        formdata.append("uploadInfo", JSON.stringify(fileUploadInfo));
      }
      var i = 0;
      while (files.length && ++i <= batchSize) {
        let file = files.shift();
        formdata.append(file.fullPath, file.file);
      }
      return formdata;
    },
    setInitialUploadState() {
      this.uploadStage = uploadStages.INACTIVE;
      this.uploadProgress = 0;
      this.files = [];
      this.analyzedFileInfo = new FileUploadInfo();
    },
    cancelDuringUpload() {
      this.uploadStage = uploadStages.CANCELLED;
    },
    cancelUpload() {
      this.setInitialUploadState();
    },
    async onPatientInfo(patientInfo) {
      // analyzed file info is reset to close patient info or stl upload dialog
      this.analyzedFileInfo = new FileUploadInfo();
      if (this.uploadStage === this.uploadStages.PATIENT_INFO) {
        await this.createPatient(this.createPatientMetadata(patientInfo));
      } else {
        await this.uploadFiles(patientInfo);
      }
    },
    async uploadFiles(fileUploadInfo) {
      // analyzed file info is reset to close patient info or stl upload dialog
      this.analyzedFileInfo = new FileUploadInfo();
      this.uploadStage = uploadStages.UPLOADING;
      try {
        if (this.selectedAsset) {
          // files uploaded in dropzone in asset
          if (fileUploadInfo.assetType === "Set") {
            await this.updateHologram(
              await this.readHologramSet(fileUploadInfo)
            );
          } else {
            await this.uploadInAsset(
              fileUploadInfo,
              this.selectedAsset,
              0,
              100,
              fileUploadInfo.files
            );
          }
        } else if (fileUploadInfo.isStl) {
          await this.uploadStl(fileUploadInfo);
        } else if (fileUploadInfo.isDicom) {
          await this.uploadDicom(fileUploadInfo);
        } else if (fileUploadInfo.assetType === "Set") {
          this.createHologram(await this.readHologramSet(fileUploadInfo));
        } else {
          let asset = await this.createAsset({
            _type: fileUploadInfo.assetType,
          });
          await this.uploadInAsset(
            fileUploadInfo,
            asset,
            0,
            100,
            fileUploadInfo.files
          );
        }
        this.setCreating(false);
      } catch (exception) {
        this.setErrorMessage(exception);
      } finally {
        this.setInitialUploadState();
      }
    },
    async readHologramSet(fileUploadInfo) {
      const files = fileUploadInfo.files;
      if (files.length !== 1) throw "only one hologram revision is allowed";

      let resultStr = await new Promise((resolve) => {
        let fileReader = new FileReader();
        fileReader.onload = () => resolve(fileReader.result);
        fileReader.readAsText(files[0].file);
      });
      return JSON.parse(resultStr);
    },
    async uploadStl(fileUploadInfo) {
      const uploadChunk = 100 / fileUploadInfo.files.length;
      let uploadBase = 0;
      let asset = null;
      for (let stlFile of fileUploadInfo.files) {
        asset = await this.createAsset({
          _type: "Surface",
          displayName: stlFile.displayName,
          surfaceType: stlFile.surfaceType,
        });
        this.updateProgress({
          assetIds: [asset.id],
          percentageCompleted: 1,
        });
        await this.uploadInAsset(
          fileUploadInfo,
          asset,
          uploadBase,
          uploadChunk,
          [stlFile],
          deleteModes.ASSET
        );
        uploadBase += uploadChunk;
      }
    },
    async uploadDicom(fileUploadInfo) {
      let deleteModeOnCancel = deleteModes.ASSET;
      if (fileUploadInfo.patientAsset) {
        this.updateModifiedTime(fileUploadInfo.patientAsset);
      } else {
        fileUploadInfo.patientAsset = await this.createDicomPatient(
          fileUploadInfo
        );
        deleteModeOnCancel = deleteModes.PATIENT;
      }
      let asset = await this.createAsset({
        _type: fileUploadInfo.assetType,
        _parentAsset: `/groups/${this.selectedGroup.id}/assets/${fileUploadInfo.patientAsset.id}`,
        _folder: true,
      });
      // set progress icon in study and patient assets to get immediate icon/feedback
      this.updateProgress({
        assetIds: [asset.id, fileUploadInfo.patientAsset.id],
        percentageCompleted: 1,
      });
      await this.uploadInAsset(
        fileUploadInfo,
        asset,
        0,
        100,
        fileUploadInfo.files,
        deleteModeOnCancel
      );
    },
    updateModifiedTime(patientAsset) {
      this.updateAssetMetadata({ asset: patientAsset, metadata: {} });
    },
    async createDicomPatient(fileUploadInfo) {
      return await this.createPatient(
        {
          _type: "Person",
          PatientId: fileUploadInfo.PatientID,
          BirthDate: fileUploadInfo.PatientBirthDate,
          PatientIdIssuer: fileUploadInfo.IssuerOfPatientID,
          PatientSex: fileUploadInfo.PatientSex,
          PatientName: fileUploadInfo.PatientName,
          FamilyName: fileUploadInfo.PatientFamilyName,
          GivenName: fileUploadInfo.PatientGivenName,
          DisplayName: fileUploadInfo.PatientName,
          _folder: true,
        },
        true
      );
    },
    async uploadInAsset(
      fileUploadInfo,
      asset,
      uploadBase,
      uploadChunk,
      uploadFiles,
      deleteMode = deleteModes.FORMAT
    ) {
      const format = await this.createFormat({ assetId: asset.id });
      const filesUrl = this.getUploadUrl(fileUploadInfo, asset.id, format.id);
      try {
        let total = uploadFiles.length;
        const batchSize = 5;
        while (uploadFiles.length > 0 && !this.isCancelled) {
          let formData = this.createFormData(
            fileUploadInfo,
            uploadFiles,
            batchSize
          );
          // Do not inline 'isPartialUpload':
          // if inlined it will be evaluated too late and cause the one but last request to be marked as not partial
          var isPartialUpload = uploadFiles.length > batchSize;
          await this.upload(filesUrl, formData, isPartialUpload);
          const percentCompleted = Math.round(
            uploadBase + (uploadChunk * (total - uploadFiles.length)) / total
          );
          this.uploadProgress = percentCompleted;
        }
        if (this.isCancelled) {
          switch (deleteMode) {
            case deleteModes.PATIENT:
              await this.deleteAzure({
                url: this.getUrlfromPath(asset.metadata._parentAsset),
              });
              break;
            case deleteModes.ASSET:
              await this.deleteAzure({ url: this.getAssetUrl(asset.id) });
              break;
            case deleteModes.FORMAT:
              await this.deleteAzure({
                url: this.getFormatUrl(asset.id, format.id),
              });
              break;
          }
          this.deleteAssetFormatFromState(format.id);
        }
      } catch (error) {
        if (this.selectedAsset) {
          this.deleteAssetFormatFromState(format.id);
        }
      }
    },
    async createPatient(metadata, isDicomPatient) {
      const url = `${appSettings.remoteurl}/api/groups/${this.selectedGroup.id}/patients`;
      metadata = metadata || {};
      metadata._author = this.user.displayName;
      let patient = await this.postAzure({ url, metadata });

      // Dicom patient creation happens while uploading a dicom,
      // It should not set the creating flow to false until uploading is complete.
      if (!isDicomPatient) {
        this.setInitialUploadState();
        this.setCreating(false);
      }
      return patient;
    },
    async createAsset(metadata) {
      const url = `${appSettings.remoteurl}/api/groups/${this.selectedGroup.id}/assets`;
      metadata = metadata || {};
      metadata._author = this.user.displayName;
      if (!this.isHome) {
        let parentAsset = this.lastBreadcrumb.asset;
        metadata._parentAsset = `/groups/${this.selectedGroup.id}/assets/${parentAsset.id}`;
      }
      return await this.postAzure({ url, metadata });
    },
    getFormatUrl(assetId, formatId) {
      return `${this.getAssetUrl(assetId)}/formats/${formatId}`;
    },
    getAssetUrl(assetId) {
      return `${appSettings.remoteurl}/api/groups/${this.selectedGroup.id}/assets/${assetId}`;
    },
    getEmptyHologramUrl() {
      return `${appSettings.remoteurl}/api/configuration/segmentationTemplates/empty`;
    },
    getHologramRevisionUrl(assetId) {
      return `${appSettings.remoteurl}/api/groups/${this.selectedGroup.id}/holograms/${assetId}/revisions`;
    },
    getUrlfromPath(path) {
      return `${appSettings.remoteurl}/api/${path}`;
    },
    getUploadUrl(fileUploadInfo, assetId, formatId) {
      if (fileUploadInfo.assetType !== "DICOMStudy") {
        return `${this.getFormatUrl(assetId, formatId)}/files`;
      }
      const uploadType = fileUploadInfo.anonymizeDicom ? "anonymize" : "upload";
      return (
        `${appSettings.anonymizerurl}/api/${uploadType}?` +
        `groupId=${this.selectedGroup.id}&assetId=${assetId}&formatId=${formatId}`
      );
    },
  },
};
</script>
<style scoped>
.spacer {
  height: 20px;
}
.footer {
  height: 65px;
}
.filterButton {
  text-transform: none;
  font-size: 14px;
  min-width: 1%;
  height: 64px;
  width: 64px;
  margin-left: 0px;
  margin-right: 0px;
  background-blend-mode: difference;
  background-color: #3d8094;
  border-radius: 50% !important;
  color: white !important;
  box-shadow: inset 2px 2px 6px rgba(0, 0, 0, 0.4);
}
.filterIcon {
  margin: auto;
  width: 40px;
  height: 40px;
  background-size: 40px 40px;
  background-repeat: no-repeat;
  background-blend-mode: difference;
}
.caption {
  color: white;
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
  text-align: center;
}
.iconAndLabel {
  display: flex;
  flex-flow: column;
  align-items: center;
}
#dropZoneIcons {
  display: flex;
  flex-flow: row;
  width: 40%;
  justify-content: space-evenly;
}
#ensureAnonymized {
  display: flex;
  flex-flow: column;
  width: 480px;
  height: 220px;
}
#chooseAssetType {
  display: flex;
  flex-flow: row;
}
#closeButton {
  align-self: end;
  margin-right: 1%;
}
.dropZone {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  color: #777;
  margin: 20px;
  border: 3px dashed white;
  border-radius: 30px;
  box-shadow: 3px 3px 20px black;
}
.uploadZone {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.innerUploadZone {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.dropText {
  font-size: 1.8em;
  text-align: center;
  color: white;
  margin: 5%;
}
.btn-large {
  background-color: #2d6b7d;
  width: 250px;
  padding: 18px 60px;
  border-radius: 10px;
  font-size: 1.5em;
  text-align: center;
  color: white;
  margin-bottom: 20px;
  cursor: pointer;
}
.md-dialog-content img {
  width: 48px;
  height: 48px;
  margin-bottom: 24px;
}
.md-dialog-content span {
  display: block;
}
</style>
