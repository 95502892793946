<template>
  <iframe
    id="unityContainer"
    ref="unityFrame"
    allowfullscreen
    :src="`/modulesViewer.html?url=${selectedGroup.id}/${selectedAsset.id}`"
  ></iframe>
</template>
<script>
import { mapState } from "vuex";
import { bus } from "../eventBus.js";

export default {
  name: "AppAssetHologram",
  computed: {
    ...mapState(["selectedGroup", "selectedAsset", "idToken"]),
  },
  watch: {
    idToken(newToken) {
      window.sessionStorage.accesstoken = newToken;
      this.sendAccessTokenToUnity();
    },
  },
  created() {
    bus.$on("unityUpdateEvent", (event) => {
      const unityFrame = this.$refs.unityFrame;
      if (!unityFrame) return;
      unityFrame.contentWindow.sendUpdateEventToUnity(event);
    });
    bus.$on("unitySaveHologram", () => {
      const unityFrame = this.$refs.unityFrame;
      if (!unityFrame) return;
      unityFrame.contentWindow.sendSaveHologramToUnity();
    });
    bus.$on("unityUnsavedChangesPrompt", () => {
      const unityFrame = this.$refs.unityFrame;
      if (!unityFrame) return;
      unityFrame.contentWindow.sendUnsavedChangesPromptToUnity();
    });
  },
  mounted() {
    window.sessionStorage.accesstoken = this.idToken;
    this.sendAccessTokenToUnity();
    // Wait for initialization and then call initializer
    var retryCount = 0;
    const initUnity = setInterval(() => {
      if (this.$refs.unityFrame && this.$refs.unityFrame.contentDocument) {
        if (
          this.$refs.unityFrame.contentDocument.readyState != "interactive" &&
          this.$refs.unityFrame.contentDocument.readyState != "ready" &&
          this.$refs.unityFrame.contentDocument.readyState != "complete"
        ) {
          return;
        }
        if (retryCount > 3) {
          clearInterval(initUnity);
          alert("Failed to load Unity. Try to refresh the page.");
          return;
        }
        try {
          this.$refs.unityFrame.contentWindow.initialize(bus);
          clearInterval(initUnity);
          retryCount = 0;
        } catch (error) {
          this.$refs.unityFrame.contentWindow.location.reload();
          retryCount++;
        }
      }
    }, 500);
  },
  methods: {
    sendAccessTokenToUnity() {
      const unityFrame = this.$refs.unityFrame;
      if (!unityFrame) return;

      // Set on window in case it is requested once Unity is loaded
      unityFrame.contentWindow.accessToken = this.idToken;
      if (unityFrame.contentWindow.sendAccessTokenToUnity)
        unityFrame.contentWindow.sendAccessTokenToUnity(this.idToken);
    },
  },
};
</script>
<style scoped>
#unityContainer {
  width: 100%;
  height: 80vh;
  padding: 20px;
  border: 0px;
}
</style>
